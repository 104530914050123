import React from "react";
import Home from "./components/Home";
import Web3 from "web3";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { injected } from "./connectors";
import { IAccount } from "./types";
import { setAccount } from "./store/actions/account";
import { configure } from "./lib/utils";
import { useMediaQuery } from "react-responsive";
import { useAccount } from "./hooks/useAccount";

const App: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const _setAccount = React.useCallback(
    (account: IAccount) => dispatch(setAccount(account)),
    [dispatch]
  );
  const _account = useAccount();
  const { ...account } = _account as any;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  React.useEffect(() => {
    // Check if wallet is aleady connected
    // set web3 provider from infura

    let web3 = new Web3.providers.HttpProvider(
      "https://mainnet.infura.io/v3/6d07c9b168114102921f5ab6808ef17f"
    );

    injected.isAuthorized().then((isAuthorized) => {
      if (
        isAuthorized &&
        JSON.parse(localStorage.getItem("connected") as string)
      ) {
        injected
          .activate()
          .then((a) => {
            _setAccount({
              address: a.account ? a.account : "",
              provider: a.provider,
            });
            configure(a.provider);
          })
          .catch((e) => {
            // TODO: Handle the error
            console.info(e);
          });
      } else {
        _setAccount({
          address: "",
          provider: web3,
        });
      }
    });
  }, [_setAccount]);

  return (
    <div className='App'>
      <Home />
    </div>
  );
};

export default App;
