import { useState, useEffect } from "react";
import { ethers } from "ethers";
import moment from "moment";

import { Button } from "@mui/material";

import { CircularProgress } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { setToast } from "../store/actions/Toast";
import { setAlert } from "../store/actions/Alert";
import { Dispatch } from "redux";
import { AiOutlineArrowUp } from "react-icons/ai";
import { GrClear } from "react-icons/gr";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaAngleDoubleRight, FaAngleDoubleDown } from "react-icons/fa";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MdKeyboardArrowDown } from "react-icons/md";

const MintCard = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 486px)" });

  const [pendigApproval, setPendingApproval] = useState<any>(false);
  const [approveDenied, setApproveDenied] = useState<any>(false);
  const [waitingCompletion, setWaitingCompletion] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [thanksTokens, setThanksTokens] = useState<any>(0);
  const [currentToken, setCurrentToken] = useState<string>("GIFTs");
  const [valueToken, setValueToken] = useState<string>("0");
  const dispatch: Dispatch<any> = useDispatch();

  // result transaction hash
  const [transaction, setTransaction] = useState<string>("");

  const approvalBody = () => {
    return (
      <div className='mint-card__waiting'>
        {approveDenied ? (
          rejectApprovalBody()
        ) : (
          <>
            {" "}
            <CircularProgress className='mint-card__waiting__spinner' />
            <h4>Waiting for approval</h4>
            <small>Confirm this transaction in your wallet</small>
          </>
        )}
      </div>
    );
  };

  const rejectApprovalBody = () => (
    <>
      <GrClear className='reject' />
      <h4 className='reject-text'>
        You rejected the transaction in your wallet
      </h4>
    </>
  );

  const submittedBody = () => {
    return (
      <div className='mint-card__submitted'>
        <div className='submitted-container-border'>
          <div className='submitted-container'>
            <AiOutlineCheckCircle />
          </div>
        </div>

        <h4>Transaction completed</h4>
        <a
          href={`https://etherscan.io/tx/${transaction}`}
          target='_blank'
          rel='noreferrer'
        >
          Etherscan
        </a>
      </div>
    );
  };

  const waitingSubmit = () => {
    return (
      <div className='mint-card__waiting'>
        <CircularProgress className='mint-card__waiting__spinner' />
        <h4>Waiting for completion</h4>
        <small>Confirm this transaction in your wallet</small>
      </div>
    );
  };

  const hanldeMaxValueToken = () => {
    setThanksTokens(1);
  };

  const handleChangeToken = (event: SelectChangeEvent) => {
    setCurrentToken(event.target.value);
    hanldeMaxValueToken();
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueToken(event.target.value);
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    setPendingApproval(true);
    setApproveDenied(false);
    setWaitingCompletion(false);
    setTransaction("");

    const provider = ethers.getDefaultProvider("ropsten");
    const wallet = new ethers.Wallet(
      "0x8d8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f",
      provider
    );

    const amount = ethers.utils.parseEther(`${valueToken}`);

    const transaction = await wallet.sendTransaction({
      to: "0x8d8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f",
      value: amount,
      gasLimit: 21000,
      gasPrice: ethers.utils.parseUnits("10", "gwei"),
    });

    setTransaction(transaction.hash);
    setWaitingCompletion(true);
  };

  const handleApprove = () => {
    setPendingApproval(false);
    setApproveDenied(false);
    setWaitingCompletion(false);
    setSubmitted(false);
    setTransaction("");
  };

  const handleDeny = () => {
    setPendingApproval(false);
    setApproveDenied(true);
    setWaitingCompletion(false);
    setSubmitted(false);
    setTransaction("");
  };

  const handleClose = () => {
    setPendingApproval(false);
  };

  const exchange = (currentToken: string, valueToken: string) => {
    const lifts = 0.483;
    const impacts = 325720000;
    const gifts = 325720000;

    switch (currentToken) {
      case "LIFTs":
        return parseFloat(valueToken) / lifts;
      case "IMPACTs":
        return parseFloat(valueToken) / impacts;
      case "GIFTs":
        return parseFloat(valueToken) / gifts;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setThanksTokens(exchange(currentToken, valueToken).toFixed(1));
  }, [valueToken, currentToken]);

  useEffect(() => {
    console.log("thanks token");

    console.log(thanksTokens);
  }, [thanksTokens]);

  return (
    <div className='mint-card'>
      {pendigApproval || waitingCompletion || approveDenied ? (
        <>{!waitingCompletion ? approvalBody() : waitingSubmit()}</>
      ) : (
        <>
          {submitted ? (
            submittedBody()
          ) : (
            <>
              <header className='mint-card__header'>
                <h4>Convert my tokens</h4>
              </header>
              <div className='mint-card__body'>
                <div className='mint-card__body__mint'>
                  <div className='mint-card__body__mint__action'>
                    <div className='mint-card__body__mint__action__select'>
                      <input
                        type='number'
                        value={valueToken}
                        onChange={(e: any) => {
                          setValueToken(e.target.value);
                        }}
                      />
                      <select
                        name='select'
                        onChange={(e: any) => {
                          setCurrentToken(e.target.value);
                        }}
                      >
                        <option value='GIFTs'>GIFTs</option>
                        <option value='LIFTs'>LIFTs</option>
                        <option value='IMPACTs'>IMPACTs</option>
                      </select>
                    </div>

                    <Button>max</Button>
                    <div className='mint-card__body__mint__action__result'>
                      <p>{isNaN(thanksTokens) ? "0" : thanksTokens} THANKS</p>
                    </div>
                  </div>
                </div>

                <div className='mint-card__footer'>
                  <Button>Convert</Button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MintCard;
