import React, { useEffect } from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  closable: boolean;
  children?: React.ReactNode;
  bodyClassName?: String;
}

const Modal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  children,
  closable,
  bodyClassName,
}) => {
  const onClose = () => (closable ? setIsOpen(false) : null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [isOpen]);

  return (
    <div className={`modal ${isOpen ? 'show' : 'hidden'}`}>
      <div className='modal--container'>
        <div className='modal--background' onClick={onClose}></div>

        <div
          className={`${
            bodyClassName ? bodyClassName : ''
          } modal--container--body`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
