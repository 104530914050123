import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import alertReducer from './alertReducer';
import toastReducer from './toastReducer';

import { State } from '../../types/index';

const reducers = combineReducers<State>({
  account: accountReducer,
  alert: alertReducer,
  toast: toastReducer,
});

export default reducers;
