import React, { useEffect } from "react";

import Modal from "./Modal";
import Button from "./Button";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { injected } from "../connectors";
import { SUPPORTED_WALLETS } from "../constants/wallets";
import { isMobile } from "../utils/userAgents";
import { setAlert } from "../store/actions/Alert";
import { IAccount } from "../types";
import { setAccount } from "../store/actions/account";
import { useAccount } from "../hooks/useAccount";
import { GrCopy } from "react-icons/gr";

import { isMobile as mobile, isAndroid } from "react-device-detect";

import Web3 from "web3";

const metamaskUrl = process.env.REACT_APP_METAMASK_URL;

interface WalletModalProps {
  open: boolean;
  handleClose: () => void;
}

const WalletModal: React.FC<WalletModalProps> = ({ open, handleClose }) => {
  // Save the account to redux
  const dispatch: Dispatch<any> = useDispatch();
  const _setAccount = React.useCallback(
    (account: IAccount) => dispatch(setAccount(account)),
    [dispatch]
  );
  const _account = useAccount();
  const { ...account } = _account as any;
  // const [pendingError, setPendingError] = React.useState<boolean>();
  // important that these are destructed from the account-specific web3-react context

  const { activate, error, deactivate } = useWeb3React();

  React.useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const disconnect = () => {
    let web3 = new Web3.providers.HttpProvider(
      "https://mainnet.infura.io/v3/6d07c9b168114102921f5ab6808ef17f"
    );
    window.location.reload();
    _setAccount({
      address: "",
      provider: web3,
    });
    deactivate();
    localStorage.setItem("connected", "false");
  };

  const tryActivation = async (connector: any | undefined) => {
    let currentWallet = "";

    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        currentWallet = SUPPORTED_WALLETS[key].name;
        return SUPPORTED_WALLETS[key].name;
      }
      return true;
    });

    connector &&
      activate(connector, undefined, true)
        .then(async () => {
          localStorage.setItem("connected", "true");
          const walletAddress = await connector.getAccount();
          console.log("Activated", currentWallet);
          _setAccount({
            address: walletAddress,
            provider:
              currentWallet === "Open in Coinbase Wallet"
                ? connector.provider
                : connector,
          });
          handleClose();

          if (currentWallet === "MetaMask") window.location.reload();
        })
        .catch((error) => {
          if (error instanceof UnsupportedChainIdError) {
            activate(connector);
          } else {
            console.error(error);
            console.log(error);

            // setPendingError(true);
          }
        });
  };

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];

      if (isMobile) {
        // if (option.name !== 'MetaMask') return null;

        if (!option.mobile) return null;

        return (
          <>
            <Button
              key={`connect-${key}`}
              onClick={() => {
                tryActivation(option.connector);
              }}
              className='option mobile'
            >
              <span className='option-container'>
                <img key={option.name} src={option.iconURL} alt={option.name} />
                {option.name}
              </span>
            </Button>
          </>
        );
      }

      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === "MetaMask") {
            return null;
          } else {
            return null;
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === "MetaMask" && !isMetamask) {
          return null;
        }
        // generic injected provider
        else if (option.name === "Injected" && isMetamask) {
          return null;
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Button
            key={`connect-${key}`}
            onClick={() => {
              tryActivation(option.connector);
            }}
            className='option'
          >
            <span className='option-container'>
              <img key={option.name} src={option.iconURL} alt={option.name} />
              {option.name}
            </span>
          </Button>
        )
      );
    });
  }

  function getModalContent() {
    return <>{getOptions()}</>;
  }

  useEffect(() => {
    if (mobile && !JSON.parse(localStorage.getItem("connected") as string)) {
      dispatch(
        setAlert({
          visible: true,
          title: "Info",
          type: "info",
          message:
            "Open the browser in metamask app if it doesn't open automatically",
        })
      );
    }
  }, [account.account.address]);

  function ConnectedWallet() {
    return (
      <>
        <header className='wallet-modal__header'>Account</header>
        <div className='wallet-modal__body'>
          <div className='wallet-modal__body__account'>
            <small>Connected with</small>
            <div className='wallet-modal__body__account__provider'>
              <p>Metamask</p>
              <Button onClick={disconnect}>Disconnect</Button>
            </div>
            <small>Address</small>
            <div className='wallet-modal__body__account__address'>
              <a
                href={`https://etherscan.io/address/${account.account.address}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {account.account.address.substr(0, 5)}...
                {account.account.address.substr(
                  account.account.address.length - 4,
                  account.account.address.length
                )}
              </a>
              <GrCopy
                onClick={() =>
                  navigator.clipboard.writeText(account.account.address)
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <Modal
      isOpen={open}
      setIsOpen={handleClose}
      closable={true}
      bodyClassName='wallet-modal-body'
    >
      <div className='wallet-modal'>
        {account.account.address &&
        account.account.address !==
          "0x93CaD765A32A68A13f9677638a578AC2a92d2d5C" ? (
          <ConnectedWallet />
        ) : (
          <>
            <header className='wallet-modal__header'>Connect Wallet</header>
            <div className='wallet-modal__body'>
              <div className='wallet-modal__body__options'>
                {getModalContent()}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default WalletModal;
