import React from "react";
import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";

const StyledButton = styled(Button)(() => ({
  padding: "5px 20px",
  backgroundColor: "#299446",
  boxShadow: "none",
  textTransform: "unset",
  fontFamily: "Poppins",
  color: "#fff",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#155a27",
    boxShadow: "none",
  },

  "&:disabled": {
    background: "#7f849b",
    color: "#fff",
  },
}));

const CustomButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default CustomButton;
