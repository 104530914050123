import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { FortmaticConnector } from '@web3-react/fortmatic-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { ALL_SUPPORTED_CHAIN_IDS, RPC_URLS } from '../constants/chains';
import { NetworkConnector } from './NetworkConnector';
import getLibrary from '../utils/getLibrary';

export const network = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: 1,
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider));
}

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
});

export const walletconnect = new WalletConnectConnector({
  // Example Wallet: Trust Wallet
  rpc: { 1: RPC_URLS[1] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  //pollingInterval: POLLING_INTERVAL,
  chainId: 1,
});

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: 'NFT connect',
});

export const fortmatic = new FortmaticConnector({
  apiKey: 'pk_live_F95FEECB1BE324B5',
  chainId: 1,
});

export const portis = new PortisConnector({
  dAppId: '790d2f80-46b8-4475-baa8-d53a7efad388',
  networks: [1, 100],
});
