import * as actionTypes from './actionTypes';
import {
  IBlockchainAction,
  DispatchBlockchainType,
  IBlockchain,
} from '../../types';

export function setBlockchain(blockchain: IBlockchain) {
  const action: IBlockchainAction = {
    type: actionTypes.SET_BLOCKCHAIN,
    payload: blockchain,
  };

  return (dispatch: DispatchBlockchainType) => {
    dispatch(action);
  };
}
