import { useEffect, useState, useCallback } from "react";
import WalletModal from "./WalletModal";
import glogo from "../assets/groupul-logo.png";
import Button from "../components/Button";
import { IAccount } from "../types";
import { Dispatch } from "redux";
import { useAccount } from "../hooks/useAccount";
import { useGetEthBalance } from "../hooks/useGetEthBalance";
import { parseNumbers } from "../utils/currency";
import { useDispatch } from "react-redux";
import { setAccount } from "../store/actions/account";
import { IoIosLogOut } from "react-icons/io";
import MintCard from "./MintCard";

const Home = () => {
  let _account: IAccount = useAccount();
  const { ...account } = _account as any;
  const [open, setOpen] = useState(false);
  const balance = useGetEthBalance();
  const handleWalletModal = () => setOpen(!open);
  const [ethBalance, setEthBalance] = useState("0.0");
  const dispatch: Dispatch<any> = useDispatch();
  const _setAccount = useCallback(
    (account: IAccount) => dispatch(setAccount(account)),
    [dispatch]
  );
  useEffect(() => {
    async function _setEthBalance() {
      const b: any = await balance;
      setEthBalance(parseNumbers(b));
    }
    _setEthBalance();
  });

  return (
    <>
      <header className='Header'>
        <div className='Header__container`'>
          Let's pull together for a better world <br />
          Humanity, animals and enviroment.
        </div>
      </header>

      <div className='content'>
        <section className='banner-section'>
          <img src={glogo} alt='logo' />
        </section>

        <section className='convert-tokens-section'>
          <header>
            {!account.account.address || account.account.address === "" ? (
              <Button
                className='wallet-btn__discovariablesnnected'
                onClick={handleWalletModal}
              >
                Connect Wallet
              </Button>
            ) : (
              <Button
                className='wallet-btn__connected'
                onClick={handleWalletModal}
              >
                <span className='wallet-btn__connected__container'>
                  <div className='left'>
                    <img
                      src={require("../assets/metamask.png").default}
                      alt=''
                    />
                    {ethBalance} {""}
                    ETH
                  </div>
                  {account.account.address.substr(0, 5)}...
                  {account.account.address.substr(
                    account.account.address.length - 4,
                    account.account.address.length
                  )}
                </span>
              </Button>
            )}
          </header>
          <article>
            <MintCard />
          </article>
        </section>

        <section className='info-section'>
          <div className='info-section__header'>
            <h3> Total Supply: 7 Billion THANKS tokens</h3>
            <h3>
              To convert your GIFT, LIFT and IMPACT tokens follow these
              instructions:
            </h3>
          </div>
        </section>

        <section className='instructions-section'>
          <article className='instructions-section__container'>
            <ol className='instructions-section__list'>
              <li>Connect your Wallet</li>
              <li>
                Select which token you want to convert, here's the equivalent
                exchange value:
              </li>

              <article>
                <p>0.483 LIFTs = 1 THANKS token</p>
                <p>325,720,000 GIFTs = 1 THANKS token</p>
                <p>325,720,000 IMPACTs = 1 THANKS token</p>
              </article>

              <li>
                Click "Max" if you want to convert all your tokens or input the
                amount you want to exchange
              </li>
              <li>Confirm the transaction on your wallet</li>
              <li>
                Wait the transaction to be finished and add the THANKS token to
                your wallet so you can see you see your new balance :)
              </li>
            </ol>
          </article>
        </section>
      </div>

      <footer className='Footer'>
        Join the project by adding liquidity to the <br />
        THANKS token pool!
      </footer>

      <WalletModal open={open} handleClose={handleWalletModal} />
    </>
  );
};

export default Home;
